<template>
  <div class="d-flex py-2">
    <AppCard color="primary" variant="tonal">
      <VCardText>
        {{ getContent() }}
      </VCardText>
    </AppCard>
  </div>
</template>

<script setup>

const prop = defineProps(['message'])

const getContent = () => {
  return prop.message.content[0].text.value
}
</script>

<style lang="scss" scoped></style>