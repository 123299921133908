<template>
  <div class="d-flex pa-2">
    <AppBtn color="primary" size="small" @click=handleAction()>
      {{ getBtnText() }}
    </AppBtn>
    <CategoryForm ref="categoryForm" @store="handleStore" />
    <ProductDialog ref="productDialog" @store="handleStore" />
  </div>
</template>

<script setup>

const prop = defineProps(['message'])

const categoryForm = ref(null)

const productDialog = ref(null)

const getBtnText = () => {
  return prop.message.content.text
}

const handleAction = () => {
  
  if (prop.message.content.component === 'categoryForm') {
    categoryForm.value.open(null, prop.message.content.categoryParams)
  }

  if (prop.message.content.component === 'productDialog') {
    productDialog.value.open(null, prop.message.content.productParams)
  }
}

const handleStore = async (response) => {
  console.log(response)
}
</script>

<style lang="scss" scoped></style>