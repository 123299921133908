<template>
  <div class="d-flex pa-2">
    <small class="text-medium-emphasis">{{ getContent() }}</small>
  </div>
</template>

<script setup>

const prop = defineProps(['message'])

const getContent = () => {
  return prop.message.content.details
}
</script>

<style lang="scss" scoped></style>